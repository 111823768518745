body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  overscroll-behavior: none;
  

}
.alignMid{
 display: flex;
 justify-content: center;
 align-items: center;

}
.scollmeinu{

    position: absolute;
    height: 340px;
    width: 340px;
  
     cursor: grab;
     touch-action: none;
     -webkit-user-select: none;
     user-select: none;
     font-size: 20px;
     color: rgb(255, 255, 255);
 
     justify-content: center;
     flex-direction: column;
     align-items: center;
     display: flex;
   }


 .shopbtn{
      
    width: 40vw;
    height: 9vw;
    border: 0px solid;
    border-radius: 0vw;
    border-color: #111;
    background-color: black;
    color: #fff;
    font-family:  "Questrial";
    font-size:16px;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    display: flex;
    font-weight:800;
    display: flex;
    align-items: center;
    justify-self: center;
    touch-action: none;
    -webkit-user-select: none;
    user-select: none;
   
     

 }
 .trybtn, .advicebtn{
  color: #ffffff;
  font-family:  "Questrial";
  font-size:20px;
  font-weight:300;
  justify-content: center;
  display: flex;
    align-items: center;
    justify-self: center;
    text-align: center;
    vertical-align: middle;
    touch-action: none;
    -webkit-user-select: none;
    user-select: none;
 }


   .text1{
    font-family: "Questrial";
    word-wrap:break-word;
    touch-action: none;
    -webkit-user-select: none;
    
    user-select: none;
   }



   .progress-bar {
    width: 100%; /* 进度条的总宽度 */
    background-color: #d0d0d0; /* 灰色背景 */
    border-radius: 4px; /* 圆角 */
    overflow: hidden; /* 防止内部进度超出边界 */
  }
  
  .progress {
    height: 4px; /* 进度条的高度 */
    background-color: #111; /* 白色进度 */
    border-radius: 2px; /* 圆角 */
    transition: width 0.3s ease-in-out; /* 平滑过渡动画 */
  }


  


 

  @font-face {
    font-family: Questrial;
    src: url('../Asset/fonts/Questrial.ttf');
  }

  